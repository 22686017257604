import React, { createContext, useContext } from "react";

const ResponseStateContext = createContext();

export const ResponseStateProvider = ({ children, value }) => {
  return (
    <ResponseStateContext.Provider value={value}>
      {children}
    </ResponseStateContext.Provider>
  );
};

export const useResponseState = () => {
  const context = useContext(ResponseStateContext);
  if (context === undefined) {
    throw new Error('useResponseState must be used within a ResponseStateProvider');
  }
  return context;
};