import React from 'react';
import { Box, Stack, Typography, Link, Grid,  } from '@mui/material';
import { useResponseState } from './Contexts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';


const Footer = () => {
  const { isMobile } = useResponseState();
  const contact = {
    address: "No. 28, Arni Road, Virupatchipuram, Vellore-632001.",
    email1: "aswin@rightexpertsmgt.com",
    email2: "rightexpertsmgt@gmail.com",
    website: "www.rightexpertsmgt.com",
    phone1: "9092153596",
    phone2: "8903665032"
  };

  const ContactItem = ({ icon: Icon, label, content }) => (
    <Box mb={1}>
      <Grid container spacing={2} alignItems="center" >
        <Grid item>
          <Icon  sx={{ mt: 0.5, color: '#2468F9' }}/>
        </Grid>
        <Grid item>
          <Typography variant={isMobile ? "body2" : "body1"} fontWeight="bold" color={'#2468F9'}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={isMobile ?'body2' : "body1"}>{content}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
  

  return (
    <section id='contact'>
    <Stack 
      direction="column" 
      width="100%" 
      px={isMobile ? 2 : 4}
      py={2}
      mt={"45px"}
      letterSpacing={2}
      textAlign={isMobile ? "left" : "start"}
      sx={{ backgroundColor: "rgb(200, 208, 214)" }}
    >
      <Typography 
        variant={isMobile ? "h6" : "h4"} 
        fontWeight="bold" 
        mb={1} 
        color='#2468F9'
      >
        Contact:
      </Typography>

      <Box maxWidth={isMobile ? "100%" : "70%"}>
        <ContactItem 
          icon={LocationOnIcon} 
          label="Location" 
          content={
            <Link href="https://maps.app.goo.gl/fzEciSwuGvY3LzvJ9" color="#393C40" underline="hover">
              {contact.address}
            </Link>
          }
        />
        
        <ContactItem 
          icon={EmailIcon} 
          label="Email" 
          content={
            <>
              <Link href={`mailto:${contact.email2}`} color="#393C40" underline="hover">
                {contact.email2}
              </Link>
              {" | "}
              <Link href={`mailto:${contact.email1}`} color="#393C40" underline="hover">
                {contact.email1}
              </Link>
            </>
          }
        />
        
        <ContactItem 
          icon={LanguageIcon} 
          label="Website" 
          content={
            <Link href={`https://${contact.website}`} color="#393C40" underline="hover">
              {contact.website}
            </Link>
          }
        />
        
        <ContactItem 
          icon={PhoneIcon} 
          label="Phone" 
          content={
            <>
              <Link href={`tel:${contact.phone1}`} color="#393C40" underline="hover">
                {contact.phone1}
              </Link>
              {" | "}
              <Link href={`tel:${contact.phone2}`} color="#393C40" underline="hover">
                {contact.phone2}
              </Link>
            </>
          }
        />
      </Box>

      <Box 
        sx={{ 
          mx: isMobile ? -2 : -8,  
          mt: 1,
          width: '100%',  

        }}
      >
        <Typography
          
          fontSize={isMobile ? "12px" : "15px"}
          textAlign={"center"}
          color="#393C40"
          fontWeight="bold"
          
        >
          COPYRIGHT © 2023 RIGHT EXPERTS MANAGEMENT SOLUTIONS - ALL RIGHTS RESERVED.
        </Typography>
      </Box>
    </Stack>
    </section>
  );
};

export default Footer;
