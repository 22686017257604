const header  = {
    homepage: "www.rightexpertsmgt.com",
    
}

const home = {
    home:"www.rightexpertsmgt.com"
}

const vision = {
    description: "To become the most competitive and leading training organization in India by providing customized training programs and by qualified and high experienced trainers. "
}

const mission = {
    description: " Providing excellent training and development which meets the needs of learners and companies, delivered by highly competent specialist staff.  "
}

const about = {
    description:
     "Right Experts Management Solutions is a corporate training providing company. We do soft skills, leadership skills, sales and customer services training, Employability skills, Quality core tools training, Safety & Maintenance Training and outbound training which meets the learners and companies needs.We do ISO Certification, Documentation and Implementation for 9001, 14001, 45001, IATF 16949, ISO 21000, ISO 22000 and FSSC 22000. Right Experts stands for its customized training programs and specialized and high experienced trainers."
}



const topics = [
    {
      title: "Business Training Programs",
      content: [
        "Monitoring and Feedback",
        "Networking",
        "Assertiveness Skills",
        "Attitude Building",
        "Business Writing Skills",
        "Winning Proposal Writing",
        "Technical Writing",
        "Report Writing",
        "Emotional Intelligence: Strategies for Success",
        "Negotiation Skills",
        "Advanced Negotiation Skills",
        "Persuasion & Influencing Skills",
        "Analyzing Skills",
        "Creativity & Out-Of-The-Box Thinking",
        "Creative Problem Solving and Decision Making",
        "International Business Etiquette",
        "International Cultural & Social Protocols",
        "Cross Cultural Communication & Awareness",
        "Excellence in Communication Skills",
        "Business Communication",
        "Communication Strategies",
        "Interpersonal Relationship",
        "Presentation Skills & Public Speaking",
        "Advanced Presentation Skills",
        "Effective Communication & Presentation Skills",
        "Managing Change for Success",
        "Effective Time Management",
        "Stress Management",
        "Business Etiquette and Protocol",
        "Supervisory Skills",
        "Advanced Supervisory Skills",
        "Managerial Effectiveness",
        "Enhancing Effectiveness",
        "Personal Effectiveness",
        "Essential Skills for Performance Excellence",
        "Achieving Excellence at Work",
        "Innovation Management",
        "Withstanding Pressure",
        "Work-Life Balance",
        "Drive for Results",
        "Relax the Body, Energies the Mind"
      ]
    },
    {
      title: "Sales and Marketing Trainings Topics",
      content: [
        "Effective Sales Communication using NLP",
        "Sales Force Management",
        "Effective Sales Management",
        "Successful Selling Skills",
        "Customer Relationship Management",
        "Customer Care Management",
        "Channel Sales Management",
        "Advanced Selling Strategies",
        "Marketing for Better Results",
        "Key Account Management: Establishing Profitable Customer Relationships",
        "Telesales: Techniques to Boost Your Phone Sales",
        "Brand Management: From Concept to Equity",
        "Effective Promotion and Advertisement Strategies",
        "Value-Based Selling: Overcoming Price Objections",
        "Retail Management",
        "Customer Loyalty: Strategies and Measurement",
        "Market Research: Foundations and Applications",
        "Customer Satisfaction Audit"
      ]
    },
    {
      title: "Leadership and Team building Programs",
      content: [
        "Coaching & Mentoring",
        "Leading for Results",
        "The Leadership Excellence Program",
        "Effective Leadership Skills",
        "Team Building and Group Dynamics",
        "Building High Performance Teams",
        "Managing Workplace Conflicts: Power through Influence",
        "Workplace Collaboration & Workplace Communication"
      ]
    },
    {
      title: "Personal Development Programs",
      content: [
        "Confidence to Succeed",
        "Achieving Goal",
        "Visual Memory Skills",
        "Management by Collective Wisdom",
        "Health & Wellness",
        "Self Motivation & Confidence Building",
        "Workforce for the future",
        "Creating Role Model Workers",
        "Customer Service Program for Worker's Leadership",
        "Leading and Coaching Workforce Attitude Building"
      ]
    },
    {
      title: "Human Resource Programs",
      content: [
        "Competency Matrix",
        "Psychometric Analysis",
        "Transaction Analysis",
        "Role Behaviour Analysis (RBA)",
        "Myers-Briggs Type Indicator (MBTI)",
        "Training Your Mind for Success with NLP",
        "Interviewing Skills",
        "Advanced Recruitment and Selection Skills",
        "HR for Non-HR Managers",
        "Performance Appraisal",
        "Succession Planning",
        "The Balanced Scorecard: Achieving Performance Excellence",
        "Behaviour Safety",
        "HR Statutory Compliance",
        "Payroll Compliance Services",
        "Labour Laws",
        "HR Audit Process",
        "Creating HR Policies and Procedures",
        "Strategic Work Force Planning",
        "How to Analyse the Training Need",
        "Total Employee Involvement",
        "Train the Trainer (TTT)"
      ]
    },
    {
      title: "Finance Trainings",
      content: [
        "Finance for Non-Finance Professionals",
        "Effective Budgeting and Cost Control",
        "Cost Management: Strategies for Business Decisions",
        "Strategic Finance for Senior Management",
        "Credit Collection",
        "Internal Audit",
        "Cost Audit",
        "Government Audit",
        "Direct and Indirect Taxation",
        "Corporate Finance",
        "Export and Import",
        "XBRL (eXtensible Business Reporting Language)"
      ]
    },
    {
      title: "Logistics Training Topics",
      content: [
        "Effective Inventory Management",
        "Logistics & Supply chain Management",
        "Vendor Management",
        "Supplier Development",
        "Single-Minute Exchange of Die (SMED)",
        "Heijunka"
      ]
    },
    {
      title: "Manufacturing and Maintenance Training Programs",
      content: [
        "Advanced Tool Design using Pro-E",
        "NX Unigraphics",
        "Ideas NX",
        "Basic Electrical Engineering",
        "Boring Machines Theory and Operation",
        "CNC Operation",
        "Design of Fixtures and Gauges",
        "Engineering Drawing",
        "Fundamental Theories of Machine Shop",
        "Heat Treatment Techniques",
        "Industrial Engineering Techniques",
        "Instrumentation and Control System",
        "Metal Finishing Techniques",
        "Milling Machine Theory and Practice",
        "Press Tool Basics and Design",
        "Theory of Constraints",
        "Tool Design",
        "Value Engineering",
        "7 Q.C. Tools",
        "Bench Marking",
        "Quality Function Development",
        "QS 9000 QMS",
        "ISO - 9000 QMS",
        "Workshop on QS 9000",
        "Statistical Techniques for QS 9000",
        "Workshop on Q-7 Tools",
        "Optimized Maintenance Planning",
        "Bearing Maintenance",
        "Workshop on N-7 Tools",
        "Quality System Audit",
        "Quality Circle",
        "SQC Techniques",
        "New 7 Tools on QC",
        "SPC Techniques",
        "Quality Internal Auditing",
        "Kaizen",
        "'5' S Activities",
        "House Keeping",
        "Control Charts to Improve Productivity",
        "Quality Cost Analysis and Profitability",
        "TQM Principles and Practice",
        "ISO 9000 2008 Internal Auditor Training",
        "ISO 14000: 2004 Internal Auditor",
        "Internal Quality Auditor for (OHSA 18001)",
        "Information Security Management Systems ISO 27001:2005",
        "Principles and Practices of Analytical Problem Solving",
        "Poke -Yoke (Mistake Proofing)",
        "Manufacturing Quality Verification",
        "Creating a World Class Visual Factory",
        "Toyota Production System",
        "Rapid Prototyping",
        "ISO / TS 16949 : 2002 Internal Quality Auditor",
        "8D Problem Solving",
        "Effective Maintenance of Cooling Systems",
        "Condition Monitoring",
        "Productivity Improvement Techniques",
        "Cost Reduction Through Elimination of Waste",
        "Lean Manufacturing",
        "3M Techniques",
        "FMEA",
        "APQP / PPAP",
        "Preventive Maintenance & Predictive Maintenance",
        "Metrology and Calibration",
        "Fundamental Theories of Machine Shop",
        "Milling Machine Theory and Practice",
        "Workshop Practice and Quality",
        "Boring Machines Theory and Operation",
        "Press tool Basics and Design",
        "Welding Technology",
        "Geometric Dimensioning and Tolerance (GD & T )",
        "Industrial Hydraulics Made Easy",
        "Industrial Hydraulics Maintenance & Trouble Shooting",
        "Painting Techniques",
        "Pressure Die Casting Die Maintenance",
        "Product Design in Pressure Die Casting",
        "Pressure Die Casting - Casting Defects and remedies",
        "Innovation in Pneumatics with PLC",
        "Heat Treatment & Metallurgy Including Cryogenics",
        "Water Treatment Practices - Ion Exchange Membrane Technology",
        "Basic Electrical Engineering",
        "Engineering Drawing",
        "Workshop Calculation",
        "Industrial Organization",
        "Machine Tool Maintenance",
        "Tool Design",
        "Work Study"
      ]
    },
    {
      title: "Safety Training Topics",
      content: [
        "Occupational Safety Health & Hygiene",
        "Safety in Operations",
        "Statutory Requirements Related to Safety Health and Environment",
        "Accident Investigation",
        "Welding Defects, Analysis ( Using NDT &DT)",
        "Machinery FMEA",
        "Low cost Automation using PLC",
        "Instrumentation and Control System",
        "Industrial Engineering Techniques",
        "Design of Experiments (DOE)",
        "Mechatronics & Robotics",
        "Design of Punch & Dies for Sheet Metal",
        "Design of Jigs & Fixtures",
        "Operation and Maintenance of Boilers",
        "Die Design in Pressure Die Casting",
        "Best Soldering Practices",
        "Advanced Energy Conservation techniques",
        "Risk Analysis",
        "On site / Off site Emergency Preparedness",
        "Electrical Safety, Chemical Safety, Fire Safety",
        "Fork Lift Safety, Gas cylinders Safety",
        "Occupational Hazards in Industry",
        "Occupational Dust",
        "Personnel Protective Equipments",
        "Statutes Related to Safety Health and Environment",
        "Awareness of Safety",
        "Safety Audits and Inspections",
        "Hazard Identification HAZOP Analysis",
        "Modern Security Systems & Procedures"
      ]
    }
  ];

const brochure = {
    pdf: "https://firebasestorage.googleapis.com/v0/b/brochure-1820.appspot.com/o/Corporate%20Profile.docx?alt=media&token=b7eb5f9d-8317-4902-bedb-b5b52304afb6"
}

const contact = {
    address: "No. 28, Arni Road, Virupatchipuram, Vellore-632001.",
    email1: "  aswin@rightexpertsmgt.com " ,
    email2: " rightexpertsmgt@gmail.com",
    website: " www.rightexpertsmgt.com",
    phone1:"9092153596 ",
    phone2: " 8903665032 "
}

const footer = {
    address: "No. 28, Arni Road, Virupatchipuram, Vellore-632001.",
    email1: "aswin@rightexpertsmgt.com " ,
    email2: "rightexpertsmgt@gmail.com",
    website: " www.rightexpertsmgt.com",
    phone1:"9092153596 ",
    phone2: " 8903665032 ",
    copyrights : "COPYRIGHT © 2023 RIGHT EXPERTS MANAGEMENT SOLUTIONS - ALL RIGHTS RESERVED.",
    linkedin: "https://Linkedin.com"
}


export {home, topics, header, mission, vision, contact, about, footer, brochure}