import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import About from './Components/About';
import { useMediaQuery, useTheme } from '@mui/material'
import { ResponseStateProvider } from './Components/Contexts'
import AboutUs from './Components/AboutUs';
import Solutions from './Components/Solutions';
import Footer from './Components/Footer';



const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="App">
      <header className="App-header">
        <ResponseStateProvider value={{ isMobile }}>
          <Navbar />
          <About />
          <AboutUs />
          <Solutions />
          <Footer />
          
        </ResponseStateProvider>
      </header>
    </div>
  );
};

export default App;