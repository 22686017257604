import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useResponseState } from './Contexts';
import { Typography, Link } from '@mui/material';

const AboutUs = () => {
  const { isMobile } = useResponseState();
  const [showFullContent, setShowFullContent] = React.useState(false);

  const DemoPaper = styled(Paper)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    width: isMobile ? "300px" : 700,
    height: isMobile ? 200 : 320,
    backgroundColor: "white",
    textAlign: "justify",
    fontWeight:"900",
    color: '#22223b',
    display: 'flex',
    margin: isMobile ? "5px" : "15px",
    cursor: "pointer",
    transition: 'all 0.6s ease-in-out',
    '&:hover': {
      backgroundColor: "#DCD9D9",
      transform: 'translateY(-5px)',
      boxShadow: '0 10px 20px rgba(15,103,177,0.3)',
    }
  }));

  const aboutUsContent = `Right Experts Management Solutions is a corporate training providing company. We do soft skills, leadership skills, sales and customer services training, Employability skills, 
  Quality core tools training, Safety & Maintenance Training and outbound training which meets the learners and companies needs.
  We do ISO Certification, Documentation and Implementation for 9001, 14001, 45001, IATF 16949, ISO 21000, ISO 22000and FSSC 22000
  Right Experts stands for its customized training programs and specialized and high experienced trainers.`;

  const truncatedContent = aboutUsContent.slice(0, 150) + '...';

  return (
    <Box
      display={isMobile ? "initial" : "flex"}
      flexDirection="column"
      width="100%"
      mt={isMobile ? 0 : 8}
      px={2}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction={isMobile ? "column" : "row"} width="100%" alignItems={isMobile ? "center" : "flex-start"} gap={isMobile ? 0 : 22} mb={isMobile ? "none" : "25px"}>
          <DemoPaper square={false} elevation={18}>
            <Typography variant={isMobile ? "body1" : "h5"} sx={{ fontFamily: "textFont", margin: isMobile ? "10px" : "70px", lineHeight: "170%" }}>
              {isMobile && <Typography variant='h5'   sx={{ marginBottom: '5px', color: 'black', textAlign: "center",backgroundColor:"#DCD9D9" }}>Vision</Typography>}
              To become the most competitive and leading training organization in India by providing customized training programs and by qualified and high experienced trainers.
            </Typography>
          </DemoPaper>
          {!isMobile && (
            <Typography 
            variant='h1' 
            mt="80px" 
            ml="-15px" 
            fontSize="75px" 
            width="400px"
           
          >
            <div className="container">
            <div id="title">&lt;&lt; vision</div>

            </div>
          </Typography>
          )}
        </Stack>

        <Stack direction={isMobile ? "column" : "row"} width="100%" alignItems={isMobile ? "center" : "flex-start"} gap={isMobile ? 0 : 38}>
          {!isMobile && (
            <Typography variant='h1' mt="80px" m="85px 0px 0px 150px" fontSize="65px" width="300px">
              <div className="container">        
                <div id="title">Mission &gt;&gt;</div>
              </div>
            </Typography>
          )}
          <DemoPaper square={false} elevation={18}>
            <Typography variant={isMobile ? "body1" : "h5"} sx={{ fontFamily: "textFont", margin: isMobile ? "10px" : "70px", lineHeight: "170%" }}>
              {isMobile && <Typography variant='h5' marginBottom="15px" sx={{ marginBottom: '10px',  color: 'black', textAlign: "center",backgroundColor:"#DCD9D9" }}>Mission</Typography>}
              Providing excellent training and development which meets the needs of learners and companies, delivered by highly competent specialist staff.
            </Typography>
          </DemoPaper>
        </Stack>

        <Stack direction={isMobile ? "column" : "row"} alignItems={isMobile ? "center" : "flex-start"} gap={isMobile ? 0 : 2} mb={isMobile ? "none" : "25px"}>
          <DemoPaper square={false} elevation={18} sx={{ height: isMobile ? (showFullContent ? 'auto' : 250) : 620 }}>
            <Typography variant={isMobile ? "body1" : "h5"} sx={{ fontFamily: "textFont", margin: isMobile ? "20px" : "70px", lineHeight: "170%" }}>
              {isMobile && <Typography variant='h5' marginBottom="15px" sx={{ marginBottom: '10px',  color: "black", textAlign: "center",backgroundColor:"#DCD9D9" }}>About Us</Typography>}
              {isMobile ? (showFullContent ? aboutUsContent : truncatedContent) : aboutUsContent}
              {isMobile && !showFullContent && (
                <Link component="button" variant="body2" onClick={() => setShowFullContent(true)} sx={{ display: 'block', marginTop: '10px', color: '#22223b', textDecoration:"underline 2px" }}>
                  Read More
                </Link>
              )}
            </Typography>
          </DemoPaper>
          {!isMobile && (
            
              <div className="container">
                <div id="title"><Typography mt={"200px"} ml={"100px"} width={"100%"} fontFamily={"headerFont"} variant='h2' textAlign={"center"}>&lt;&lt;  about us</Typography></div>
              </div>
           
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AboutUs;