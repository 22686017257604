import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import Header from "../images/Headerimage.png"
import { useResponseState } from './Contexts'

const About = () => {
    const { isMobile } = useResponseState()
   
    return (
        <Stack
            direction={isMobile ? "column" : "row"}
            alignItems="center"
            sx={{ height: '100vh' }}
        >
                <Box
                    component="img"
                    src={Header}
                    alt='Header'
                    sx={{
                        width: isMobile ? "100%" : "70%",
                        height: "100%",
                        objectFit: "cover",
                        maskImage: "linear-gradient(to right, black 40%, transparent 100%)",
                        WebkitMaskImage: "linear-gradient(to right, black 40%, transparent 100%)",
                    }}
                />
            
            <Stack
                spacing={2}
                alignItems={"center"}
                sx={{
                    width: isMobile ? "100%" : "30%",
                    padding: isMobile ? "20px" : "10px 0 0 30px",
                    marginTop: isMobile ? "30px" : "0",
                    
                    
                }}
            >
                <Typography
                    variant="h1"
                    sx={{

                        fontFamily: "headerFont",
                        fontSize: { lg: "95px", md: "60px", sm: "50px", xs: "45px" },
                        lineHeight: 1,
                        textShadow:isMobile ? "none" : "2px 2px #727275",
                        textAlign: "center",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        animation: "wave 2s ease-in-out infinite alternate ",
                    }}
                >
                    <span style={{ color: "#2468F9" }}>R</span>
                    <span style={{ color: "#FF7F3E" }}>E</span>
                </Typography>
               
                <Typography
                    variant="h3"
                    sx={{
                        fontFamily: "headerFont",
                        fontSize: { lg: "34px", md: "20px", sm: "18px", xs: "16px" },
                        letterSpacing: "2px",
                        color: "#2468F9",
                        textShadow: isMobile ? "none" : "2px 2px #727275",
                        textTransform: "uppercase",
                        textAlign: "center",
                        lineHeight: 1.2,
                        animation: "wave 2s ease-in-out infinite alternate ",
                    }}
                >
                    Right Experts Management Solutions
                </Typography>
               
                <Typography
                    sx={{
                        fontFamily: "bottomFont",
                        fontSize: { lg: "25px", md: "18px", sm: "18px", xs: "18px" },
                        color: "#FF7F3E",
                        fontWeight: "bold",
                        textAlign: isMobile ? "center" : "left",
                        animation: "wave 2s ease-in-out infinite alternate ",
                    }}
                >
                    Consulting | Training | Outsourcing
                </Typography>
            </Stack>
        </Stack>
    )
}

export default About