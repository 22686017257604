import React from 'react'
import { AppBar, Toolbar, Box, Link, Typography, Stack} from '@mui/material'
import { useResponseState } from './Contexts'

const Navbar = () => {
  const { isMobile } = useResponseState();

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar position={isMobile ? "sticky" : "relative"} sx={{ bgcolor: 'rgb(235, 238, 240)' }} >
      <Toolbar sx={{ justifyContent: 'space-between', padding: isMobile ? "5px" : "20px" }}>
        <Stack direction={isMobile ? "column" : "row"}>
          <Typography fontSize={isMobile ? "25px" : "60px"} textAlign={"start"} fontFamily={"headerFont"} paddingLeft="5px" letterSpacing={1}>
            <span style={{ color: "#2468F9" }}>R</span>
            <span style={{ color: "#FF7F3E" }}>E</span>
          </Typography>
          <Typography fontSize={isMobile ? "10px" : "20px"} color="#2468F9" textAlign={"left"}  fontWeight={isMobile ? 400 : 700} gap={1} paddingLeft={isMobile ? "5px" : "10px" } paddingTop={isMobile ? "0px" : "10px"}>
            Right Experts <br /> Management Solutions
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', gap: isMobile ? 3 : 10, paddingRight: isMobile ? "5px" : "25px" }}>
          <Link 
            href="#solutions" 
            onClick={(e) => scrollToSection(e, 'solutions')}
            variant={isMobile ? "body1" : "h5" }
            sx={{
              fontWeight:"normal",
              color: "#2468F9",
              textDecoration: "none",
              fontFamily:"textFont",
              textTransform:"uppercase",
              '&:hover': {
                color: '#F86F03',
                cursor: "pointer",
              },
            }}
          >
            solutions
          </Link>
          <Link 
            href="#contact" 
            onClick={(e) => scrollToSection(e, 'contact')}
            variant={isMobile ? "body1" : "h5"}
            sx={{
              fontWeight:"normal",
              textTransform:"uppercase",
              color: '#2468F9',
              textDecoration: 'none',
              fontFamily:"textFont",
              '&:hover': {
                color: '#F86F03',
                cursor: "pointer"
              },
            }}
          >
            contact
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar