import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useResponseState } from './Contexts';
import { Stack } from '@mui/material';
import { topics, brochure } from '../Contents';





export default function ScrollDialog() {
  const { isMobile } = useResponseState();
  const [openDialog, setOpenDialog] = React.useState(null);
  const descriptionElementRef = React.useRef(null);

  const StyledPaper = styled(Paper)(() => ({
    backgroundColor: "rgb(235, 238, 240)",
    fontFamily: "textFont",
    textAlign: 'center',
    color: "black",
    cursor: 'pointer',
    border:" 5px solid white",
    boxShadow: '5px 15px 15px -15px rgba(0,0,0,0.5), 15px 5px 15px -15px rgba(0,0,0,0.5)',
    borderRadius: isMobile ? "10px" : "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-20px)'
    }
  }));

  const AnimatedButton = styled('a')(({ theme }) => ({
    display: 'block',
    width: isMobile ? "100px" : '150px',
    height: isMobile ? "40px" : '50px',
    fontFamily: 'textFont',
    textDecoration: 'none',
    color: '#2468F9',
    marginTop:isMobile ?"50px" : "25px",
    border: '3px solid #2468F9',
    alignItems:"center",
    textAlign: 'center',
    position: 'relative',
    transition: 'all .35s alternate infinite',
    '&:after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      width: 0,
      height: '100%',
      background: '#2468F9',
      transition: 'all .35s',
    },
    '&:hover': {
      color: '#fff',
    },
    '&:hover:after': {
      width: '100%',
    },
    '& span': {
      position: 'relative',
      zIndex: 2,
    },
  }));

  const handleClickOpen = (index) => {
    setOpenDialog(index);
  };

  const handleClose = () => {
    setOpenDialog(null);
  };

  const handleBrochureClick = (event) => {
    event.preventDefault();
    window.open(brochure.pdf, '_blank');
  };

  React.useEffect(() => {
    if (openDialog !== null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  return (
    <section id='solutions'>
    <Stack direction={"column"} alignItems={"center"}>
      <Typography variant='h1' mt={isMobile ? "40px" : "80px"} ml={isMobile ? "450px" : "200px"} fontSize="65px" width="700px">
        {!isMobile ? (
          <div className="container">
            <div id="title-topics">solutions</div>
          </div>
        ) : (
          <Typography variant='h4' fontFamily={"headerFont"}  alignSelf={"center"} color={"#f86f03"} sx={{textShadow:"2px 2px gray", animation: "wave 2s infinite"}}>Solutions</Typography>
        )}
      </Typography>
      <Box mt={isMobile ? "25px" : "80px" }
        sx={{
          display: "flex",
          flexWrap: 'wrap',
          alignItems: "center",
          border: !isMobile ? "4px solid gray" : "none",
          borderRadius: "2px",
          padding:isMobile ?"0px" : "25px",
          paddingBottom: isMobile ?"0px" : "75px",
          marginLeft:isMobile ? "5px" : "75px",
          marginRight:isMobile ? "5px" : "75px",
          justifyContent: 'center',
          gap: isMobile ? 3 : 10
        }}
      >
        {topics.map((topic, index) => (
          <StyledPaper
            key={index}
            onClick={() => handleClickOpen(index)}
            sx={{
              width: isMobile ? '150px' : "170px",
              height: isMobile ? '70px' : "120px",
              marginTop:isMobile ? "15px" : "25px"
            }}
          >
            <Typography fontSize={isMobile ? "14px":"15px"} margin={isMobile ? "none":"3px"} fontWeight={"bold"} sx={{letterSpacing:isMobile ? "none":"1.5px"}}>{topic.title}</Typography>
          </StyledPaper>
        ))}
        <Dialog
          open={openDialog !== null}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title"><Typography color={"#0000CD"} mt={"10px"} fontSize={isMobile?"22px":"25px"} fontWeight={"bold"} alignItems={"center"}>{openDialog !== null ? topics[openDialog].title : ''}</Typography></DialogTitle>
          <DialogContent dividers={'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {openDialog !== null && (
                <Typography fontSize={isMobile ? "16px":"18px"} color={"black"} lineHeight={isMobile ? "28px":"45px"}><ul>
                  {topics[openDialog].content.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul></Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button variant="contained" size="small" onClick={handleClose}>Back</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box mt={isMobile ? "5px":"45px"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AnimatedButton href="#" onClick={handleBrochureClick}>
          <span><Typography fontSize ={isMobile ?'12px' : "16px"} mt={isMobile ? "5px" : "0px"} fontWeight={600}>Download Brochure</Typography></span>
        </AnimatedButton>
      </Box>
    </Stack>
    </section>
  );
}